import React, { createContext, useContext, useState, ReactNode } from 'react';
import type { IBreadCrumb } from '../Interfaces/IBreadCrumb';

interface BreadcrumbContextType {
    breadcrumb: IBreadCrumb[];
    setBreadcrumb: React.Dispatch<React.SetStateAction<IBreadCrumb[]>>;
}

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

export const BreadcrumbProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [breadcrumb, setBreadcrumb] = useState<IBreadCrumb[] | []>([]);

    return (
        <BreadcrumbContext.Provider value={{ breadcrumb, setBreadcrumb }}>
            {children}
        </BreadcrumbContext.Provider>
    );
};

export const useBreadcrumb = (): BreadcrumbContextType => {
    const context = useContext(BreadcrumbContext);
    if (!context) {
        throw new Error('useBreadcrumb must be used within a BreadcrumbProvider');
    }
    return context;
};
