import { Power, UserRound } from "lucide-react"
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom"
import { tokenPayload, type IJwtPayload } from "../../helpers/jwt";
import { handleLogout } from "../logout";

export const MenuUserNavBar = () => {
    const [userData, setUserData] = useState<IJwtPayload>({});
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    useEffect(() => {
        setUserData(tokenPayload() ?? null);
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                onClick={toggleDropdown}
            >
                <UserRound className="text-gray-800 bg-slate-50 border-2 rounded-full" size={28} />
            </button>

            {dropdownOpen && (
                <div
                    className="z-50 absolute right-0 mt-2 w-48 text-base list-none bg-white divide-y divide-gray-400 rounded shadow border-1 dark:bg-gray-900 dark:divide-gray-600 dark:border-gray-800"
                    id="dropdown-user"
                >
                    <div className="py-3 px-4">
                        <span className="block text-sm font-semibold text-gray-900 dark:text-gray-400">
                            {userData.nome}
                        </span>
                        <span className="block text-sm text-gray-900 truncate dark:text-gray-400">
                            {userData.email}
                        </span>
                    </div>
                    <ul className="py-1 text-gray-700 dark:text-gray-300" aria-labelledby="dropdown">
                        <li>
                            <Link
                                to="/users/account"
                                className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
                            >
                                Meu Perfil
                            </Link>
                        </li>
                    </ul>
                    <ul className="py-1 text-gray-700 dark:text-gray-300" aria-labelledby="dropdown" >
                        <li>
                            <div
                                onClick={handleLogout}
                                className="flex flex-row gap-4 py-2 px-4 text-sm cursor-pointer hover:bg-gray-600"
                            >
                                <Power size={18} className="text-red-500" />
                                <span className="text-red-500">Sign out</span>
                            </div>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    )
}