import type { ISublimacaStatus } from '../../Interfaces/ISublimacaoStatus';

interface StatusAtivoInativoProps {
    status: ISublimacaStatus
}

export const SublimacaoStatusComponent = ({ status }: StatusAtivoInativoProps) => {
    return (
        <span style={{ background: `${status.color}` }} className={`flex whitespace-nowrap items-center justify-center px-3 py-1 text-sm font-semibold rounded-lg text-[#FFF]`}>
            {status.label}
        </span>
    );
};
