import React, { useEffect, useMemo, useState } from "react";
import { useBreadcrumb } from "../../contexts/BreadcrumbContext";
import type { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import { useOrcamentos } from "../orcamento/useOrcamentos";
import { NotFoundData } from "../../components/not-found";

export const BaseHome: React.FC = () => {
    const { setBreadcrumb } = useBreadcrumb();
    const [totalHeight, setTotalHeight] = useState<number>();
    const [series, setSeries] = useState<any[]>([]); // Criar uma única série para todos os status
    const [categories, setCategories] = useState<string[]>([]); // Para o eixo X
    const [colors, setColors] = useState<string[]>([]); // Armazenar as cores
    const [filteredData, setFilteredData] = useState<any[]>([]); // Armazenar dados filtrados para uso global
    const [totalGeral, setTotalGeral] = useState<number>(0); // Armazenar o total geral

    const { transformarEmOrdemServicoPorStatus, isLoading, isFetching } = useOrcamentos("Todos");
    useEffect(() => {
        setBreadcrumb([]);
    }, [setBreadcrumb]);

    const ordemServicoPorStatus = useMemo(() => {
        if (isLoading || isFetching) return []; // Verifica se ainda está carregando
        return transformarEmOrdemServicoPorStatus();
    }, [isLoading, isFetching, transformarEmOrdemServicoPorStatus]);

    useEffect(() => {
        if (!ordemServicoPorStatus.length) return; // Verifica se existem dados válidos

        // Ordena do maior para menor e filtra os dados para remover o status "Todos"
        ordemServicoPorStatus.sort((a, b) => b.totalOrderServico - a.totalOrderServico);
        var removeFinalizados = ordemServicoPorStatus.filter(item => item.status.key !== 7);
        var removeCancelados = removeFinalizados.filter(item => item.status.key !== 6)
        const filteredData = removeCancelados.filter(item => item.status.key !== 99);
        setFilteredData(filteredData); // Atualiza o estado com os dados filtrados

        // Calcular o total geral
        const total = filteredData.reduce((acc, item) => acc + item.totalOrderServico, 0);
        setTotalGeral(total); // Atualiza o total geral

        // Criar uma única série com os dados
        const seriesData = [{
            name: 'Total Ordem de Serviço',
            data: filteredData.map(item => item.totalOrderServico),
        }];

        // Definir categorias no eixo X
        const xCategories = filteredData.map(item => item.status.label);

        // Definir cores para cada barra
        const barColors = filteredData.map(item => item.status.color);

        setSeries(seriesData);
        setCategories(xCategories);
        setColors(barColors); // Atualizar o estado das cores

        setTotalHeight(heightAuto(filteredData.length));
    }, [ordemServicoPorStatus]);

    const heightAuto = (totalrows: number): number => {
        return totalrows <= 7 ? 450 : totalrows * 64.28571428571429;
    };

    const options: ApexOptions = {
        colors: colors, // Usar cores diretamente do estado
        chart: {
            type: 'bar', // Mantém o tipo de gráfico como "bar"
            zoom: {
                enabled: true,
                type: 'x', // permite zoom horizontal
                autoScaleYaxis: true,
            },
            toolbar: {
                autoSelected: 'zoom',
            },
            width: '100%'
        },
        plotOptions: {
            bar: {
                distributed: true, // Distribui as barras para cada série separadamente
                dataLabels: {
                    position: 'center', // Exibe os data labels no centro das barras
                },
                horizontal: true
            }
        },
        dataLabels: {
            enabled: true, // Ativar dataLabels para mostrar as porcentagens
            formatter: function (val: number, _opts: any) {
                // Calcular a porcentagem do total geral
                const percentage = ((val / totalGeral) * 100).toFixed(2);
                return `${percentage}%`; // Mostrar a porcentagem
            },
            style: {
                colors: ['#ffffff'] // Cor branca para melhor contraste com o fundo azul
            }
        },
        legend: {
            show: true,
        },
        tooltip: {
            shared: false, // Tooltip para cada série separadamente
            intersect: false,
            y: {
                formatter: function (_value: number, { seriesIndex, dataPointIndex, w }) {
                    const statusTotal = w.globals.series[seriesIndex][dataPointIndex]; // Total do status atual
                    return `Total Geral: ${totalGeral}, Total do Status: ${statusTotal}`; // Mostrar o total geral e o total do status
                },
            },
        },
        xaxis: {
            categories: categories, // Adiciona os nomes dos status no eixo X
            labels: {
                show: false,
                style: {
                    fontFamily: "Inter, sans-serif",
                    cssClass: "text-xs dark:fill-gray-300 text-black w-9/12",
                },
                rotate: -45, // Rotacionar etiquetas para evitar sobreposição
            },
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    fontFamily: "Inter, sans-serif",
                    cssClass: "text-xs dark:fill-gray-300 text-black w-1/4",
                },
                maxWidth: 150
            },
        }
    };

    return (
        <>
            <div className="flex flex-col gap-4">
                <div className="flex flex-row justify-between gap-2">
                    <div className="flex flex-col gap-1">
                        <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">
                            Dashboard
                        </h2>
                        <span className="text-gray-900 dark:text-gray-400 font-semibold">
                            {/* Exibir a frase longa em telas maiores (XL e acima) */}
                            <small className="text-gray-900 dark:text-gray-400 font-semibold hidden xl:inline">
                                Os dados exibidos nesta tela referem-se aos últimos 30 dias.
                            </small>
                            {/* Exibir a frase curta em telas menores (abaixo de XL) */}
                            <small className="text-gray-900 dark:text-gray-400 font-semibold inline xl:hidden">
                                Dados referentes aos últimos 30 dias.
                            </small>
                        </span>
                    </div>

                </div>

                {filteredData.length === 0 && (<NotFoundData />)}

                {filteredData && filteredData.length >= 1 && (
                    <>
                        <div className="grid grid-cols-12 gap-2">
                            {filteredData && filteredData.map(item => (
                                <Link
                                    key={item.status.key}
                                    to={`./order-servico?status=${item.status.name}`}
                                    className={`relative cursor-pointer flex flex-row justify-between items-center border-b-2 p-4 col-span-full bg-gs-light sm:col-span-6 xl:col-span-4 shadow-lg rounded-lg ext-zinc-300`}
                                    style={{ borderBottomColor: item.status.color }} // Adiciona cor dinâmica ao border-bottom
                                >
                                    {item.status.label}
                                    <span className="text-xl" style={{ color: item.status.color }}>{item.totalOrderServico}</span>
                                </Link>
                            ))}
                        </div>
                        <div className="flex ho flex-col gap-6 p-4 col-span-full sm:col-span-12 xl:col-span-6 shadow-md rounded-xl border border-slate-200 dark:border-slate-800 bg-gs-light">
                            <ReactApexChart options={options} series={series} type="bar" height={totalHeight} />
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
