import { Power } from "lucide-react";
import { appUrl } from "../config";

type LogoutProps = {
    label?: string | null;
}

export const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('url_api_client');
    sessionStorage.removeItem('searchData');
    window.location.href = `${appUrl}/login`
};

export const LogoutApp: React.FC<LogoutProps> = ({ label }) => {
    return (
        <button
            onClick={handleLogout}
            className="flex flex-row text-danger gap-4 items-center w-auto justify-between cursor-pointer p-2 mr-1 rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:text-danger-400 dark:hover:text-white dark:hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600">
            <Power size={18} />
            {label && label !== null && label !== undefined ? <span>{label}</span> : ""}
        </button>
    );
};