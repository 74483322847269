//verifica se a conexão com a internet
export const IsNetworkConnection = () => {

    const isOnline = () => {
        return navigator.onLine;
    }

    if (!isOnline()) {
        window.location.href = '/erro-connection-network'
    }

    return isOnline();
}