import React from "react";
import unauthorized from "../assets/unauthorized.svg"
import { Link } from "react-router-dom";

export const NotUnauthorized: React.FC<any> = () => {
    return (
        <>
            <div className="flex justify-center">
                <div className="flex flex-col justify-center items-center">
                    <img src={unauthorized} alt="unauthorized" width="250" />
                    <h3 className="text-xl dark:text-zinc-50">Erro, Unauthorized</h3>
                    <span className="text-sm dark:text-zinc-50">Volte, acesse sua conta clicando no botão abaixo</span>
                </div>
                <div className="fixed bottom-0 p-3">
                    <Link className={`w-80 flex flex-col  bg-[#0157A5] rounded-lg hover:bg-slate-400 p-3 items-center  uppercase text-sm text-zinc-50 font-semibold`} to={'/login'} >  Fazer login  </Link>
                </div>
            </div>
        </>
    );
}