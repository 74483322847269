import { Outlet } from "react-router-dom";
import ThemeContextProvider from "../../Providers/useTheme";

export default function PaginaBaseLogin() {

	return (
		<>
			<ThemeContextProvider>
				<div className="mx-auto max-w-lg">
					<Outlet />
				</div>
			</ThemeContextProvider>
		</>
	);
}
