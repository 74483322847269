import { IBreadCrumb } from "../Interfaces/IBreadCrumb";
import { Home } from "lucide-react";
import { Link } from "react-router-dom";
import { useBreadcrumb } from "../contexts/BreadcrumbContext";

export const MyBreadCrumb: React.FC = () => {
    const { breadcrumb } = useBreadcrumb();
    
    return (
        <>
            <nav className="flex" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li key={'home'} className="inline-flex items-center">
                        <Link to="/home"
                            className="inline-flex gap-2 items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                            <Home size={18} />
                            <span className="">Dashboard</span>
                        </Link>
                    </li>
                    {breadcrumb && breadcrumb?.map((item: IBreadCrumb, index: number) =>
                        <li key={index} aria-current="page">
                            <div className="flex items-center">
                                <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                                </svg>
                                <div className="flex flex-row gap-2 ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">
                                    {item.icon ?? ""}
                                    <span className={item.icon === undefined ? `` : `hidden sm:inline ml-2`}>{item.label}</span> {/* hidden sm:inline ml-2 */}
                                </div>
                            </div>
                        </li>
                    )}
                </ol>
            </nav>
        </>
    )
};
