import { AlertOctagon } from "lucide-react";

interface NotFoundDataProps {
    titulo?: string;
    subtitulo?: string;
}


export const NotFoundData = ({ titulo = 'Ops! Nenhum dado encontrado.', subtitulo }: NotFoundDataProps) => {
    return (
        <div className="flex flex-col justify-center items-center text-center dark:text-zinc-50 p-3">
            <div className="flex flex-col justify-center items-center gap-2">
                <AlertOctagon size={32} className="text-red-500" />
                <h3 className="text-xl font-semibold mt-3">{titulo}</h3>
                {subtitulo && (
                    <span className="text-sm mt-1">
                        Nenhum dado corresponde ao filtro selecionado.<br />
                        Tente ajustar os critérios de filtro.
                    </span>
                )}
            </div>
        </div>
    );
}