import { IFiltro } from "../Interfaces/IFiltro";

export class Filtro implements IFiltro {

    DtInicio: string;
    DtFinal: string;
    Status: string;

    constructor(
        dataInicial: string,
        dataFim: string,
        status: string
    ) {
        this.DtInicio = dataInicial;
        this.DtFinal = dataFim;
        this.Status = status;
    }

    public queryParamsFilterUrl() {

        const paramsString: any = {
            DtInicio: this.DtInicio,
            DtFinal: this.DtFinal,
            Status: this.Status
        };

        const queryParams = new URLSearchParams(paramsString).toString();

        const url = `${queryParams.toString()}`;
        return url;
    }
}
