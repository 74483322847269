import React, { createContext, useState, useContext } from 'react';
import { IShareDataContext } from '../Interfaces/IShareDataContext';

interface IShareDataContextProps {
    children: React.ReactNode;
}

export const ShareDataContext = createContext<IShareDataContext<any> | null>({
    dataShare: null,
    setShareData: () => { }
});

export const ShareDataProvider: React.FC<IShareDataContextProps> = ({ children }) => {
    const [dataShare, setShareData] = useState<IShareDataContext<any> | null>(null);

    /* const setShareData = (newData: any) => {
        setShareData(newData);
    }; */

    return (
        <ShareDataContext.Provider value={{ dataShare, setShareData, }}>
            {children}
        </ShareDataContext.Provider>
    );
};

export const useShareData = () => {
    const context = useContext(ShareDataContext);
    if (!context) {
        throw new Error('useShareData must be used within a ShareDataProvider');
    }
    return context;
};
