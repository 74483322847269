import axios from "axios";

axios.defaults.timeout = 30000;
const http = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL_BASE}`,
    headers: {
        Accept: "application/json",
        Content: "application/json",
    },
});

http.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("token");

        if (token && config.headers) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    function (erro) {
        throw new Error(erro);
    }
);

export default http;
