import React, { useEffect, useState } from "react";
import { useBreadcrumb } from "../../contexts/BreadcrumbContext";
import ReactApexChart from "react-apexcharts";
import { formatNumeroParaReal, formatValue } from "../../helpers/helpers";
import type { ApexOptions } from "apexcharts";
import { useWindowSize } from "../../Hooks/useWindowSize";

interface Props {
    loadCard: boolean;
}

export const Home: React.FC<Props> = () => {
    const { setBreadcrumb } = useBreadcrumb();
    const size = useWindowSize()
    const [dataSeries,] = useState<any>();
    // const [totalHeight, setTotalHeight] = useState<number>();

    useEffect(() => {
        setBreadcrumb([])
    }, [setBreadcrumb])


    const series: ApexAxisChartSeries = [
        {
            name: 'Realizado',
            data: dataSeries || [],
        }
    ];

    const options: ApexOptions = {
        colors: ['#0157A5'],
        chart: {
            sparkline: {
                enabled: false,
            },
            type: 'bar',
            zoom: {
                enabled: true,
                type: 'x', // permite zoom horizontal
                autoScaleYaxis: true,
            },
            toolbar: {
                autoSelected: 'zoom',
            },
            events: {
                // dataPointSelection(_e, _chart, options) {
                //     if (entriesData) {
                //         const dataSelection: IEvolucao = entriesData[options.dataPointIndex]
                //         setDataPointSelection(dataSelection);
                //     }
                // },
            },
            width: '100%'
        },
        plotOptions: {
            bar: {
                horizontal: true,
                barHeight: 45
            }
        },
        dataLabels: {
            formatter: function (val: any) {
                return `R$ ${formatValue(val, 1)}`;
            },
            style: {
                colors: ['#ffffff'] // Cor branca para melhor contraste com o fundo azul
            }
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            customLegendItems: ['Realizado', 'Meta'],
            markers: {
                fillColors: ['#0157A5', '#00E396']
            }
        },
        stroke: {
            curve: 'smooth',
        },
        tooltip: {
            shared: true,
            intersect: false,
            x: {},
            y: {
                formatter: function (value: number) {
                    return `${formatNumeroParaReal(value, "$")}`;
                },
            },
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    fontFamily: "Inter, sans-serif",
                    cssClass: "text-xs dark:fill-gray-300 text-black w-1/4",
                },
                maxWidth: size.width <= 480 ? 80 : 150
            },
        },
        xaxis: {
            labels: {
                show: true,
                style: {
                    fontFamily: "Inter, sans-serif",
                    cssClass: "text-xs dark:fill-gray-300 text-black w-9/12",
                },
                formatter: function (val: any) {
                    return formatValue(val);
                },
                rotate: -45, // Rotacionar etiquetas para evitar sobreposição
            },
        }
    };

    return (
        <>
            <div className="grid grid-cols-12 gap-6">
                <div className="flex ho flex-col gap-6 p-4 col-span-full sm:col-span-12 xl:col-span-6 shadow-md rounded-xl border border-slate-200 dark:border-slate-800 bg-gs-light">
                    <ReactApexChart options={options} series={series} type="bar" height={450} />
                </div>
            </div>
        </>
    );
};
