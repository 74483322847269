import { useState } from "react";
import { Card, CardBody } from "@nextui-org/react";
import { toast } from 'sonner'
import { LoadSpinner } from "../../components/load-spinner";
import emailValidator from "../../helpers/emailValidator";
import { AxiosResponse } from "axios";
import { IAuthContext } from "../../Interfaces/IAuthContext";
import { useShareData } from "../../Providers/ShareDataContext";
import { IErroResponse } from "../../Interfaces/IErroResponse";
import { ErrorHandles } from "../../helpers/error-handles";
import { IsNetworkConnection } from "../../helpers/is-network-connection";
import { Button } from "../../components/button";
import { Eye, EyeOff, Unlock, User } from "lucide-react";
import type { IAuthResponse } from "../../Interfaces/IAuthResponse";
import authStore from "../../authentica/authentication.store";
import http from "../../http";

interface IMsgErro {
    style?: string;
    message?: string;
    isErro: boolean;
}

export function SignIn() {

    IsNetworkConnection();
    const { setShareData } = useShareData();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const [msgError, setMsgError] = useState<IMsgErro | null>(null);
    const [loading, setLoading] = useState(false);
    // const navigate = useNavigate();

    //const {isLoggedIn, setIsLoggedIn } = useAuth(); // Usa o estado de autenticação

    // set email and password
    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");

    async function handleSubmit(e: any) {
        e.preventDefault();

        setLoading(true); // Exibição do indicador de carregamento.
        IsNetworkConnection();
        if (login === "" || password === "") {
            toast.error('Por favor, preencha todos os campos...', { position: "top-center" })
            setMsgError({
                style: "flex text-red-600",
                message: "Por favor, preencha todos os campos...",
                isErro: true
            });
            setLoading(false);
            return false;
        }
        // Verifica se o campo de e-mail está vazio.
        // Usando o objeto de validação de e-mail
        if (!emailValidator.validate(login, setLoading)) {
            setMsgError({ isErro: false })
            return;
        }
        // Verifica se o campo de senha está vazio.
        if (password === "") {
            // Se a senha estiver vazia, exibe um toast de erro solicitando que o usuário digite a senha.
            setMsgError({
                style: "flex text-red-600",
                message: "Por favor, preencha todos os campos...",
                isErro: true
            });
            // Encerra o indicador de carregamento, pois a validação falhou.
            setLoading(false);
            // Retorna falso para impedir a continuação do processo de autenticação.
            return false;
        }

        //cria o objeto para enviar no corpo da requisão da api
        const user = { login: login, senha: password };

        try {
            // Envia uma requisição POST para a rota '/account/auth/dominio' com os dados do usuário para autenticação.
            const dataResponse: AxiosResponse<IAuthResponse> = await http.post(`/cliente/auth`, user);

            setLoading(false);
            const data = dataResponse.data.data;
            const shareData: IAuthContext = {
                data: data
            };
            authStore.login({ email: login, token: data.token });
            localStorage.setItem("token", data.token); // Armazena o token recebido no armazenamento da sessão.
            setShareData(shareData);
            //navigate("/home");
            window.location.href = "./home";

        } catch (e: any) {

            setLoading(false);
            const error: IErroResponse = e;
            const isRedirect = ErrorHandles(error, true, "/login");

            if (isRedirect) {
                //navigate("/home");
                //window.location.href = "./home";
                return;
            }

        }
    }

    const handleTypePassword = () => {
        setShowPassword(prevShowPassword => !prevShowPassword)
    }

    return (
        <>
            <section className="shadow-sm flex flex-col justify-center h-screen mx-auto px-2">
                <div className="justify-content-center form-bg-image row">
                    <div className="d-flex align-items-center justify-content-center col-12">
                        <div className="shadow-soft rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                            {loading ? (
                                <LoadSpinner message={`Analisando dados enviado...`} />
                            ) : (
                                <form onSubmit={handleSubmit} className="form flex flex-col space-y-6">

                                    <div className="flex flex-col gap-1 sm:flex-row justify-center items-center font-bold text-3xl text-zinc-100">
                                        <span>Acomp.</span>
                                        <span>Ordem de Serviço</span>
                                    </div>
                                    <Card className={`dark:bg-gray-900 p-1 border-1 py-8  border-gray-700 light:border-gray-900 w-full shadow-lg`}>
                                        <CardBody className="space-y-6 py-4 px-4">

                                            <div className="flex flex-col border-none space-y-3">
                                                <label className="text-sm font-semibold flex flex-row items-center gap-2">
                                                    <User />
                                                    Login
                                                </label>
                                                <input
                                                    onChange={(e) => {
                                                        setLogin(e.target.value);
                                                        setMsgError({ isErro: false })
                                                    }}
                                                    autoComplete="user_email"
                                                    value={login} type="email" className="bg-gs-light rounded-lg focus:border-gray-700 border-gray-700 p-3" placeholder="email@exemplo.com" name="user_email" />
                                            </div>

                                            <div className="flex flex-col border-none space-y-3">
                                                <label className="text-sm font-semibold flex flex-row items-center gap-2">
                                                    <Unlock />
                                                    Senha
                                                </label>
                                                <div className="flex flex-row gap-2 items-center bg-gs-light rounded-lg">
                                                    <input
                                                        autoComplete="current-password"
                                                        onChange={(e) => { setPassword(e.target.value); setMsgError({ isErro: false }) }}
                                                        value={password} type={showPassword ? 'text' : 'password'} className="bg-gs-light rounded-lg focus:border-none flex-1 border-none p-3" placeholder="*********" name="user_password" />
                                                    <div
                                                        onClick={handleTypePassword}
                                                        className="text-zinc-200 flex justify-center items-center hover:bg-gray-800 rounded-lg px-4 py-2 gap-2 cursor-pointer">
                                                        {!showPassword ? <EyeOff /> : <Eye />}
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                        {msgError?.isErro ? (
                                            <>
                                                <div className="h-7 mt-3 flex justify-center">
                                                    <span className={msgError?.style}>
                                                        {msgError?.message}
                                                    </span>
                                                </div>
                                            </>
                                        ) : ("")}
                                    </Card>

                                    <Button type="submit">
                                        Entrar
                                    </Button>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
