import {
	useContext,
	createContext,
	useState,
	useEffect,
	ReactNode,
} from "react";


interface ThemeContextType {
	theme: string;
	setTheme: (theme: string) => void;
}


const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

interface Props {
	children: ReactNode;
}

export default function ThemeContextProvider({ children }: Props) {

	const [darkModel] = useState(localStorage.getItem("theme")??"dark");
	const [theme, setTheme] = useState(darkModel);


	useEffect(() => {
		
		const root = document.documentElement;
		const removeTheme = (theme === "dark" ? "light" : "dark");
		root.classList.remove(removeTheme);
		root.classList.add(theme);

		localStorage.setItem("theme", theme);
	}, [theme]);

	return (
		<ThemeContext.Provider value={{ theme, setTheme }}>
			{children}
		</ThemeContext.Provider>
	);
}

export function useTheme() {
	const context = useContext(ThemeContext);

	if (!context) throw new Error("useTheme must be used within a ThemeContextProvider");

	return context;
}
