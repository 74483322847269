import type React from "react";
import { CustomHttpClient, type IHttpClient } from "../http/http-axios-client";
import type { IOrcamento } from "../Interfaces/IOrcamento";

export class OrcamentoService {

    protected httpClient: IHttpClient;
    private setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;

    constructor(setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) {
        this.httpClient = new CustomHttpClient();
        this.setIsLoading = setIsLoading;
    }

    async get(params?: string): Promise<IOrcamento[]> {
        this.setIsLoading(true);
        try {
            const setParam = params ? `?${params}` : '';
            const response = await this.httpClient.get<IOrcamento[]>(`/orcamento${setParam}`);
            return response ? response : [];
        } catch (error) {
            throw error
        } finally {
            this.setIsLoading(false);
        }
    }
}