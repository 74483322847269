import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import PaginaBase from "./pages/PaginaBase";
import PaginaBaseLogin from "./pages/PaginaBaseLogin";
import { NotFoundHandler } from "./pages/not-found";
import RouterPrivate from "./helpers/RoutePrivate";
import RoutePublic from "./helpers/RoutePublic";
import { Home } from "./pages/home";
import { SignIn } from "./pages/auth/sign-in";
import { NotUnauthorized } from "./pages/not-unauthorized";
import { NotConnection } from "./pages/not-connection";
import UseAccount from "./pages/account";
import { BaseHome } from "./pages/home/base";
import {  OrderServico } from "./pages/orcamento";

export const AppRoutes = () => {

    return (
        <BrowserRouter basename={process.env.REACT_APP_BASEPATH}>
            <Routes>

                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="/" element={<RouterPrivate />}>
                    <Route path="/" element={<PaginaBase />}>

                        <Route path="/" element={<BaseHome />}>
                            <Route path="home" element={<Home loadCard={true} />} />
                        </Route>

                        <Route path="order-servico" element={<OrderServico />} />
                        <Route path="/users/account" element={<UseAccount />} />
                    </Route>
                </Route>

                <Route path="/" element={<RoutePublic />}>
                    <Route path="/" element={<PaginaBaseLogin />}>
                        <Route path="/login" element={<SignIn />}></Route>
                        <Route path="/not-unauthorized" element={<NotUnauthorized />} />
                        <Route path="/not-connection" element={<NotConnection />} />
                        <Route path="/erro-connection-network" element={<NotConnection />} />
                    </Route>
                </Route>

                <Route path="*" element={<NotFoundHandler />} />
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;
