import type { IAuthTokenPayload } from "../Interfaces/IAuthTokenPayload";
import { formattedCurrentDate, formattedStartOfMonth } from "../js/utilits";

import { Filtro } from "../Models/Filtro";

const formatToTwoDecimals = (value: number) =>
    parseFloat((value || 0.0).toFixed(2));

function formatarValorComScala(value: number) {

    if (isNaN(value) || value === 0) {
        return 0.0;
    }


    const SuffixScale = ["", "K", "MI", "BI", "TRI"];
    const scale = Math.floor(Math.log10(Math.abs(value)) / 3);
    const valorFormat = value / Math.pow(1000, scale);
    const valorRounded = parseFloat(valorFormat.toFixed(2));
    return `R$ ${formatNumeroDecimal2(valorRounded)}${SuffixScale[scale]}`;

}

const formatNumeroParaReal = (
    numero: number,
    tipo?: string
) => {
    // Verifica se o número é válido
    if (isNaN(numero) || numero === 0) {
        return 0.0;
    }

    switch (tipo) {
        case "%":
            return formatNumeroDecimal2(numero) + "%";
        case "$":
            return `R$ ${formatNumeroDecimal2(numero, 0)}`;
        default:
            return formatNumeroDecimal2(numero, 0);
    }
};

const formatNumeroDecimal2 = (value: number, digitis: number = 2) => {
    return value.toLocaleString("pt-BR", {
        minimumFractionDigits: digitis,
        maximumFractionDigits: digitis,
    });
};

const toDateTime = (value: number) => {
    let svalue = value.toString().padStart(7, "0");

    let ano = parseInt(svalue.substring(1, 3));
    let mes = parseInt(svalue.substring(3, 5)).toString().padStart(2, "0");
    let dia = parseInt(svalue.substring(5, 7));

    if (ano > 39) {
        ano += 1900;
    } else {
        ano += 2000;
    }

    return `${dia}/${mes}/${ano}`;
};

const formatarNumeroComEscala = (numeroString: string) => {
    const sufixosEscala = ["", "K", "MI", "BI", "TRI"];

    const numero = parseFloat(numeroString.replace(/\./g, "").replace(",", "."));
    if (isNaN(numero)) return "Número inválido";

    if (numero === 0) return "0";

    const escala = Math.floor(Math.log10(Math.abs(numero)) / 3);
    const valorFormatado = numero / Math.pow(1000, escala);
    const valorArredondado = parseFloat(valorFormatado.toFixed(2));

    return `${valorArredondado}${sufixosEscala[escala]}`;
};

const CheckPositiveNegative = (value: number, isReverseSign: boolean = false) => {

    var numero = value;

    if (isReverseSign)
        numero = value * -1;

    return numero > 0 ? 'positivo' : numero < 0 ? 'negativo' : 'zero';
};

const getLast30Days = () => {
    const currentDate = new Date(); // Data atual
    const last30Days = new Date(); // Nova data baseada na data atual
    last30Days.setDate(currentDate.getDate() - 30); // Subtrai 30 dias da data atual
    return getFormattedDate(last30Days); // Retorna a data formatada
};

const getFormattedDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
};

const filterDefaultInitial = (status?: string) => {
    const formattedStartOfMonth_ = formattedStartOfMonth();
    const formattedCurrentDate_ = formattedCurrentDate();

    JSON.stringify({
        DtInicio: formattedStartOfMonth_,
        DtFinal: formattedCurrentDate_,
        Status: status ? status : 'abertos'
    });

    var filterData = new Filtro(formattedStartOfMonth_, formattedCurrentDate_, status ? status : "AbertoEsperandoAprovacao");
    return filterData.queryParamsFilterUrl();
};

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const getYesterday = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const month = yesterday.getMonth() + 1 < 10 ? `0${yesterday.getMonth() + 1}` : yesterday.getMonth() + 1;
    const day = yesterday.getDate() < 10 ? `0${yesterday.getDate()}` : yesterday.getDate();
    return `${yesterday.getFullYear()}-${month}-${day}`;
}

const formatValue = (val: number, fractionDigits: number = 0): string => {
    const sign = val < 0 ? '-' : '';
    const absVal = Math.abs(val);

    if (absVal >= 1e9) return sign + (absVal / 1e9).toFixed(fractionDigits) + 'B';
    if (absVal >= 1e6) return sign + (absVal / 1e6).toFixed(fractionDigits) + 'M';
    if (absVal >= 1e3) return sign + (absVal / 1e3).toFixed(fractionDigits) + 'k';
    return sign + absVal.toString();
}

/**
 * Converte um número inteiro em uma cor hexadecimal no formato #RRGGBB.
 * @param {number} colorValue - O número que representa a cor.
 * @returns {string} - A cor em formato hexadecimal.
 */
const intToHexColor = (colorValue: number) => {
    // Extrair os valores de RGB do número
    const red = (colorValue >> 16) & 0xFF;    // Pega os 8 bits superiores para o Red
    const green = (colorValue >> 8) & 0xFF;   // Pega os 8 bits do meio para o Green
    const blue = colorValue & 0xFF;           // Pega os 8 bits inferiores para o Blue

    // Converter para formato hexadecimal
    const hexColor = `${red.toString(16).padStart(2, '0')}${green.toString(16).padStart(2, '0')}${blue.toString(16).padStart(2, '0')}`;
    return hexColor.toUpperCase();
}

const decodeTokenJtw = (token: string): IAuthTokenPayload | null => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );
        return JSON.parse(jsonPayload);
    } catch (error) {
        console.error('Invalid token:', error);
        return null;
    }
};
const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
};

export {formatCurrency,
    getFormattedDate,
    getLast30Days,
    decodeTokenJtw,
    intToHexColor,
    formatValue,
    formatarValorComScala,
    getYesterday,
    capitalizeFirstLetter,
    filterDefaultInitial,
    formatarNumeroComEscala,
    toDateTime,
    formatNumeroParaReal,
    formatToTwoDecimals,
    CheckPositiveNegative,
};
