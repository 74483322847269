import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { appUrl } from "../config";

export interface IHttpClient {
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>;
    post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
    put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>;
}

export class CustomHttpClient implements IHttpClient {
    private readonly instance: AxiosInstance;
    private readonly maxRetries: number;

    constructor(maxRetries: number = 5) {

        this.instance = axios.create({
            baseURL: `${process.env.REACT_APP_API_URL_BASE}`,
            timeout: 10000,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
        this.maxRetries = maxRetries;
        this.setupInterceptors();
    }

    private setupInterceptors(): void {
        this.instance.interceptors.request.use(
            (config) => {
                const token = localStorage.getItem("token");

                if (token && config.headers) {
                    config.headers.Authorization = `Bearer ${token}`;
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );
    }

    async get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response = await this.instance.get<T>(url, config);

            if (response.status === 200) {  // Check if status code is 200
                return response.data;
            }

        } catch (error) {
            if (axios.isAxiosError(error)) { // Verifique se o erro é do Axios
                if (error.response?.status === 401) {
                    window.location.href = `${appUrl}/login`;
                }
                if (error.response?.status === 400) {
                    throw error.response.data.erro // Retorne os dados no erro 400
                }
                console.error(`failed due to an error. Retrying...`, error);
            } else {
                console.error("An unexpected error occurred.", error);
            }
        }

        console.error(`All ${this.maxRetries} attempts failed.`);
        throw new Error(`Failed to fetch data from ${url} after ${this.maxRetries} attempts`);
    }

    async post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.instance.post<T>(url, data, config);
        return response.data;
    }

    async put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const response = await this.instance.put<T>(url, data, config);
        return response.data;
    }
}
