import { forwardRef, type ComponentProps } from "react";
import { tv, type VariantProps } from 'tailwind-variants';

const inputVariants = tv({
    base: 'appearance-none block w-full bg-gray-200 text-gray-700 dark:text-gray-400 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500',

    variants: {
        variant: {
            primary: 'focus:ring-2 focus:ring-blue-500',
            secondary: 'focus:ring-2 focus:ring-gray-500',
            danger: 'border-red-500 focus:ring-2 focus:ring-red-500'
        },
        variantSize: {
            small: 'text-sm h-8',
            medium: 'text-base h-10',
            large: 'text-lg h-12'
        }
    },

    defaultVariants: {
        variant: 'primary',
        variantSize: 'medium'
    }
});

interface InputProps extends ComponentProps<'input'>, VariantProps<typeof inputVariants> {
    label?: string;
    ref: any
}

export const Input = ({ variant, variantSize, ...props }: InputProps) => {
    return (
        <>
            <input {...props} className={inputVariants({ variant, variantSize })} />
        </>
    );
}

export const InputWithLabel = forwardRef<HTMLInputElement, InputProps>(({ variant, variantSize, label, ...props }, ref) => {
    return (
        <>
            {label && (
                <label htmlFor={props.id} className="block uppercase tracking-wide text-gray-700 dark:text-gray-400 text-xs font-bold mb-2">
                    {label}
                </label>
            )}
            <input {...props} ref={ref} className={inputVariants({ variant, variantSize })} />
        </>
    );
});

InputWithLabel.displayName = 'InputWithLabel';