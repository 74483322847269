import { IErroResponse } from "../Interfaces/IErroResponse";
import { ExternalToast, toast } from "sonner";



export const ErrorHandles = (error: IErroResponse, isRedirect: boolean = false, _urlRedirect: string = "") => {
    const options: ExternalToast =
    {
        position: "top-center"
    }



    switch (error.code) {
        case 'ERR_NETWORK':
            //window.location.href = '/not-connection'
            toast.error("Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.", options);
            break;
        case 'ERR_BAD_REQUEST':

            if (error.response?.data.erro) {
                toast.error(error.response?.data.erro.message, options);
                break;
            }

            toast.error("Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.", options);
            break;
        case 'ECONNABORTED':
            toast.error("O servidor de destino está demorando para processar sua solicitação. Por favor, tente novamente mais tarde.", options);
            break;
        case '404':
            toast.error("Erro 404: Recurso não encontrado", options);
            break;
        case '500':
            toast.error("O servidor de destino está demorando para processar sua solicitação. Por favor, tente novamente mais tarde.", options);
            break;
        default:
            toast.error("Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.", options);
            break;
    }

    if (isRedirect) {
        return isRedirect;
    }

};
