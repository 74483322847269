import { useSearchParams } from "react-router-dom";
import { useBreadcrumb } from "../../contexts/BreadcrumbContext";
import { useLoadingSpinner } from "../../Providers/loader-context";
import { useCallback, useEffect, useState } from "react";
import { getAdjustedDate, getToday } from "../../helpers/date-utils";
import type { ISublimacaStatus } from "../../Interfaces/ISublimacaoStatus";
import { useAuthToken } from "../../Hooks/useAuthToken";
import { OrcamentoService } from "../../services/OrcamentoSerivce";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import type { IFiltro } from "../../Interfaces/IFiltro";
import { Filtro } from "../../Models/Filtro";
import SublimacaoStatusData from "../../datas/StatusSublimacao.json"
import type { IOrcamento } from "../../Interfaces/IOrcamento";

export const useOrcamentos = (statusInitial?: string) => {
console.log(statusInitial);

    const [param] = useSearchParams();
    const { setBreadcrumb } = useBreadcrumb();
    const { setIsLoading } = useLoadingSpinner();
    const [isModalFilter, setIsModalFilter] = useState(false);
    const [filter, setFilter] = useState<string | undefined>(() => {
        const status = param.get("status") ?? statusInitial ?? "AguardandoAprovacao"
        return `DtInicio=${getAdjustedDate(30)}&DtFinal=${getToday()}&Status=${status}`
    });


    const [selectedStatus, setSelectedStatus] = useState<string>(param.get("status") ?? statusInitial ?? "AguardandoAprovacao");
    const [selectedDescriptionStatus, setSelectedDescriptionStatus] = useState<string>(() => {
        console.log(selectedStatus);
        
        return SublimacaoStatusData.filter((item: ISublimacaStatus) => item.name === selectedStatus)[0].label
    });

    const { payload } = useAuthToken();

    useEffect(() => {
        setBreadcrumb([{ label: "Ordem de Serviço" }])
    }, [setBreadcrumb])

    const orcamentoService = new OrcamentoService(setIsLoading)

    const { data: orcamentos, isLoading, isFetching, refetch, isError, error } = useQuery({
        queryKey: ["orcamentos", selectedStatus, filter],
        queryFn: () => orcamentoService.get(filter!),
        enabled: !!filter,
        placeholderData: keepPreviousData,
        retry: false,
    });

    const hendlerOpenModalFilter = () => {
        setIsModalFilter((prev) => !prev)
    }

    // Função para aplicar os novos filtros definidos no modal
    const handleModalData = useCallback(
        (data: IFiltro) => {
            const newFilter = new Filtro(data.DtInicio, data.DtFinal, data.Status).queryParamsFilterUrl();
            setFilter(newFilter);
            setIsModalFilter(false);

            const newFilterSelected: ISublimacaStatus[] = SublimacaoStatusData.filter((item: ISublimacaStatus) => item.name === data.Status)

            setSelectedDescriptionStatus(newFilterSelected[0].label)
            setSelectedStatus(data.Status)
            refetch(); // Refetch após aplicar novos filtros do modal
        },
        [refetch]
    );

    useEffect(() => {
        if (filter) {
            refetch(); // Refetch após o filtro ser atualizado
        }
    }, [filter, refetch]);

    // Função para transformar o JSON da API em ordemServicoPorStatus
    const transformarEmOrdemServicoPorStatus = useCallback((): any[] => {
        if (!orcamentos || orcamentos.length === 0) {
            return []; // Return an empty array if orcamentos is undefined or empty
        }

        // Your existing logic
        const ordemServicoPorStatus = Object.values(
            orcamentos.reduce((acc: any, item: IOrcamento) => {
                if (item.statusSublimacao.show === false) {
                    return acc;
                }

                const statusKey = item.statusSublimacao.key;
                if (!acc[statusKey]) {
                    acc[statusKey] = {
                        totalOrderServico: 0,
                        status: {
                            key: item.statusSublimacao.key,
                            name: item.statusSublimacao.name,
                            label: item.statusSublimacao.label,
                            color: item.statusSublimacao.color,
                            show: item.statusSublimacao.show,
                        },
                    };
                }

                acc[statusKey].totalOrderServico += 1;

                return acc;
            }, {})
        );
        return ordemServicoPorStatus;
    }, [orcamentos]); // Include 'orcamentos' in the dependency array

    return {
        data: orcamentos, isLoading, isFetching, refetch, isError, error,
        isModalFilter,
        selectedDescriptionStatus,
        payload,
        hendlerOpenModalFilter,
        handleModalData,
        selectedStatus, setSelectedStatus,
        transformarEmOrdemServicoPorStatus
    }
}