import { NavLink, useLocation } from "react-router-dom";

export type PropsMenuItem = {
    id?: number;
    icon: JSX.Element;
    title: string;
    to: string;
    allowed?: boolean
}

export const MenuItem: React.FC<PropsMenuItem> = ({ icon, title, to, allowed }) => {
    const location = useLocation();
    const currentUrl = location.pathname;

    if(!allowed) return;

    return (
        <li key={title}>
            <NavLink
                to={to}
                className={`text-sm font-bold leading-9 tracking-tight ${currentUrl === to ? 'dark:text-gray-100 dark:bg-gray-700 bg-gray-300' : 'text-gray-900 dark:text-gray-100'} flex items-center p-2 rounded-lg dark:hover:bg-gray-700 hover:bg-gray-300  group`}
            >
                {icon}
                <span className="ml-3">{title}</span>
            </NavLink>
        </li>
    );
};