import React from "react";
import noConnection from "../assets/no-connection-seccundary.svg"
import { Link } from "react-router-dom";

export const NotConnection: React.FC<any> = () => {
    return (
        <>
            <div className="flex justify-center">
                <div className="flex flex-col justify-center items-center dark:text-zinc-50">
                    <img src={noConnection} alt="unauthorized" width="250" />
                    <h3 className="text-xl">Erro, no connection</h3>
                    <span className="text-sm">Volte, Tente novamente clicando no botão abaixo</span>
                </div>
                <div className="fixed bottom-0 p-3 justify-center ">
                    <Link className={`w-80 flex flex-col  bg-[#0157A5] rounded-lg hover:bg-slate-400 p-3 items-center  uppercase text-sm text-zinc-50 font-semibold`} to={'/login'} >  Tente novamente  </Link>
                </div>
            </div>
        </>
    );
}