interface ErrorFallbackProps {
    error: any
    resetErrorBoundary: () => void
}
export const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-red-100 text-red-800 text-center p-4">
            <h1 className="text-2xl font-bold mb-4">Ops! Algo deu errado.</h1>
            <p className="mb-6">{error.message}</p>
            <button
                onClick={resetErrorBoundary}
                className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-4 rounded"
            >
                Tentar novamente
            </button>
        </div>
    );
}
