import type React from "react";
import { CustomHttpClient, type IHttpClient } from "../http/http-axios-client";
import type { IResponse } from "../Interfaces/IResponse";

export class AccountService {

    protected httpClient: IHttpClient;
    private setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;

    constructor(setIsLoading: React.Dispatch<React.SetStateAction<boolean>>) {
        this.httpClient = new CustomHttpClient();
        this.setIsLoading = setIsLoading;
    }

    async resetSenha(params?: any): Promise<IResponse<any>> {
        this.setIsLoading(true);
        try {
            const response = await this.httpClient.post<IResponse<any>>(`/cliente/auth/reset/`, params);
            return response.data ? response.data : [];
        } catch (error) {
            throw error
        } finally {
            this.setIsLoading(false);
        }
    }
}