import React from "react";

interface HeaderComponentProps {
    title: string;
    subTitle?: string | null;
    buttons?: JSX.Element
}

export const HeaderComponent: React.FC<HeaderComponentProps> = ({ title, subTitle, buttons }: HeaderComponentProps) => {
    return (
        <div className="flex flex-row justify-between gap-2">
            <div className="flex flex-col">
                <h2 className="text-xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">
                    {title}
                </h2>
                {subTitle && (
                    <span className="text-gray-900 dark:text-gray-400 text-sm font-semibold hidden xl:inline">
                        {subTitle}
                    </span>
                )}
            </div>
            {buttons}
        </div>
    )
}