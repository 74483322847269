// StatusEnum.ts
export enum SublimacaoStatus {
    AbertoEsperandoAprovacao = 0, // Aberto ESPERANDO APROVACAO
    Aprovado = 1,                 // APROVADO
    FilaDeImpressao = 2,          // FILA DE IMPRESSAO
    Impresso = 3,                 // IMPRESSO
    Entregue = 4,                 // ENTREGUE
    FinanceiroSemFinalizar = 5,   // FINANCEIRO SEM FINALIZAR
    CanceladoServico = 6,         // CANCELADO SERVIÇO
    FaturadoPeloPDV = 7,          // FATURADO O SERVIÇO PELO PDV - NO FINANCEIRO
    ServicoEmAndamento = 8,       // SERVICO EM ANDAMENTO
    ServicoExecutadoAguardandoClienteFinanceiro = 9, // SERVIÇO EXECUTADO AGUARDANDO CLIENTE FINANCEIRO
    AguardandoLiberacao = 10,
    Todos = 99
}
