import { useState } from "react";
import { Link } from "react-router-dom";
import { AlignLeft, XIcon } from "lucide-react";
import { AsideMenu } from "./../menu";
import { MenuUserNavBar } from "./menu-user";

const NavBar = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    const closeMenu = () => {
        setMobileMenuOpen(false);
    };

    return (
        <>
            <nav className="bg-white border-b border-gray-200 px-4 py-2.5 dark:bg-gray-900 dark:border-gray-800 fixed left-0 right-0 top-0 z-50">
                <div className="flex flex-wrap justify-between items-center">
                    <div className="flex justify-start items-center">
                        <button
                            data-drawer-target="drawer-navigation"
                            data-drawer-toggle="drawer-navigation"
                            aria-controls="drawer-navigation"
                            className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        >
                            {!mobileMenuOpen ? (<AlignLeft />) : (<XIcon className="w-6 h-6" />)}
                            <span className="sr-only">Toggle sidebar</span>
                        </button>
                        <Link
                            to={`/dashboard/home`}
                            className="flex items-center justify-between mr-4"
                            id="drawer-navigation"
                        >
                            <span className="self-center text-[18px] font-semibold whitespace-nowrap dark:text-white">
                                {/* Exibir o nome original em telas grandes (a partir de MD) */}
                                <span className="hidden md:inline">
                                    Acomp.  Ordem de Serviço
                                </span>
                            </span>
                        </Link>
                    </div>
                    <div className="flex items-center gap-3">
                        <MenuUserNavBar />
                    </div>
                </div>
            </nav>

            <AsideMenu id="logo-sidebar" isOpen={mobileMenuOpen} closeMenu={closeMenu} />
        </>
    );
}

export default NavBar;
