import { getFormattedDate, getLast30Days } from "../helpers/helpers";

const decodeToken = () => {
    return JSON.parse(
        window.decodeURIComponent(
            escape(window.atob(get_set_SessionStorage("token").split(".")[0]))
        )
    );
};
const getToken = () => {
    return get_set_SessionStorage("token");
};
const checksSession = () => {
    if (!get_set_SessionStorage("token")) {
        window.location.href = "/";
    }
    return false;
};
const formatNumber_by_Money = (v) => {
    v = v || 0;
    return v.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
};
const formatNumber = (v) => {
    v = v || 0;
    return v.toLocaleString("pt-BR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
};
const formatNumber_by_Money_Acronym = (v, maximumDigits = 0) => {
    v = v || 0;
    const abbreviations = ["", "K", "MI", "BI", "TRI"];
    const negative = v < 0;
    var absValue = Math.abs(v);
    let abbreviationIndex = 0;

    while (absValue >= 1000 && abbreviationIndex < abbreviations.length - 1) {
        absValue /= 1000;
        abbreviationIndex++;
    }

    const formattedValue = new Intl.NumberFormat("pt-BR", {
        currency: "BRL",
        minimumFractionDigits: 0,
        maximumFractionDigits: maximumDigits,
    }).format(absValue);

    return `${negative ? "- " : ""} ${formattedValue}${abbreviations[abbreviationIndex]}`;
};
const formatNumber_by_Money_Converter = (v, typeStyle = "currency") => {
    v = v || 0;
    const absValue = Math.abs(v);
    const roundValue = Math.round(absValue);
    let valor = v;
    let abbreviations = "";

    if (absValue >= 1000) {
        valor = roundValue / 1000;
        abbreviations = "K";
    }

    valor = Math.abs(valor.toFixed(0));
    const formattedValue = valor.toLocaleString("pt-BR", {
        style: typeStyle,
        currency: "BRL",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    if (v < 0) {
        return "-" + formattedValue + abbreviations;
    }

    return formattedValue + abbreviations;
};
const formatNumber_by_Percentage = (v) => {
    v = v || 0.0;
    return v.toFixed(2);
};


const formatDate = (v) => {
    return v.split("-").reverse().join("/");
};
const TypeDate = () => {
    const hoje = new Date();
    const trintaDiasAtras = new Date();
    trintaDiasAtras.setDate(hoje.getDate() - 30);
    const ano = trintaDiasAtras.getFullYear();
    const mes = trintaDiasAtras.getMonth() + 1;
    const dia = trintaDiasAtras.getDate();
    const datePassada = `${ano}-${mes.toString().padStart(2, "0")}-${dia
        .toString()
        .padStart(2, "0")}`;
    const dateAtual = `${hoje.getFullYear()}-${(hoje.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${hoje.getDay().toString().padStart(2, "0")}`;

    return {
        datePassada,
        dateAtual,
    };
};
const clear_SessionStorage = (iten = null) => {
    if (iten) {
        window.sessionStorage.removeItem(iten);
        return;
    }
    window.sessionStorage.clear();
};
const get_set_SessionStorage = (key, value = null) => {
    if (value) {
        window.sessionStorage.setItem(key, value);
        return;
    }
    return window.sessionStorage.getItem(key);
};
const clear_LocalStorage = (iten = null) => {
    if (iten) {
        window.localStorage.removeItem(iten);
        return;
    }
    window.localStorage.clear();
};
const get_set_LocalStorage = (key, value = null) => {
    if (value) {
        window.localStorage.setItem(key, value);
        return;
    }
    return window.localStorage.getItem(key);
};

// Subtrai um dia da data fornecida. Por exemplo, se a data atual for 26/08/2024, 
// a função retornará 25/08/2024.

// const getStartOfMonth = () => {
//     const currentDate = new Date();
//     const startOfMonth = new Date(
//         currentDate.getFullYear(),
//         currentDate.getMonth(),
//         1
//     );
//     return getFormattedDate(startOfMonth);
// };

const currentDate = new Date();
const formattedCurrentDate = () => getFormattedDate(currentDate);
const formattedStartOfMonth = () => getLast30Days();

export {
    decodeToken,
    getToken,
    checksSession,
    formatNumber,
    formatNumber_by_Money,
    formatNumber_by_Percentage,
    formatNumber_by_Money_Converter,
    formatNumber_by_Money_Acronym,
    formatDate,
    TypeDate,
    clear_SessionStorage,
    get_set_SessionStorage,
    clear_LocalStorage,
    get_set_LocalStorage,
    formattedStartOfMonth,
    formattedCurrentDate,
};
