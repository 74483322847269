import { jwtDecode } from "jwt-decode";

export interface IJwtPayload {
	nome?: string;
	email?: string;
	nbf?: number;
	exp?: number;
	iat?: number;

}

const tokenPayload = (): IJwtPayload => {
	try {
		const token = getToken();
		return jwtDecode<IJwtPayload>(token);
	} catch (e: any) {
		throw new Error(e.message);
	}
};

const getToken = () => {
	const token = localStorage.getItem("token");
	if (!token) throw new Error("Invalid token");
	return token;
};

export { tokenPayload, getToken };
