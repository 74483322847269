import React, { createContext, useContext, ReactNode } from 'react';
import { CustomHttpClient } from '../http/http-axios-client';

// Interface para definir o formato dos dados injetados
interface Dependencies {
    httpClient: CustomHttpClient;
}

// Criar o contexto com um valor inicial vazio
const HttpClientContext = createContext<Dependencies | null>(null);

// Componente Provedor que envolve toda a aplicação para fornecer as dependências
export const HttpClientProvider: React.FC<{ httpClient: CustomHttpClient, children: ReactNode }> = ({ httpClient, children }) => {
    return <HttpClientContext.Provider value={{ httpClient }}>{children}</HttpClientContext.Provider>;
};

// Hook personalizado para acessar as dependências em qualquer parte da árvore de componentes
export const useDependencies = () => {
    const dependencies = useContext(HttpClientContext);
    if (!dependencies) {
        throw new Error('useDependencies must be used within a HttpClientProvider');
    }
    return dependencies;
};
