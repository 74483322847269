import type { ComponentProps, ReactNode } from "react";
import { tv, type VariantProps } from 'tailwind-variants'

const buttonVariants = tv({
    base: 'rounded-lg px-5 h-10 max-h-10 py-2 justify-center flex items-center gap-2',

    variants: {
        variant: {
            primary: 'bg-[#C7D7FA] text-[#002D90] hover:bg-blue-400 font-semibold',
            secondary: 'bg-gray-900 text-zinc-200  hover:bg-gray-700',
            transpatent: 'text-zinc-200 hover:bg-gray-800 border-1 border-gray-700',
            danger: 'text-danger-300 hover:bg-gs font-semibold'
        },
        size: {
            default: 'py-1',
            full: 'w-full h-11',
            small: 'h-6 rounded-sm py-2'
        }
    },

    defaultVariants: {
        variant: 'primary',
        size: 'default'
    }
})

interface ButtonProps extends ComponentProps<'button'>, VariantProps<typeof buttonVariants> {
    children: ReactNode,
}

export const Button = ({ children, variant, size, ...props }: ButtonProps) => {
    return (
        <button {...props} className={buttonVariants({ variant, size })}>
            {children}
        </button>
    )
}