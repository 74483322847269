import { useEffect, useState } from "react"

interface IWindowSize {
    width: number
    height: number
}

export const useWindowSize = () => {

    const [windowSize, setWindowSize] = useState<IWindowSize>({
        height: window.innerHeight,
        width: window.innerWidth
    })

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }

        window.addEventListener('resize', handleResize)
        //return () => window.removeEventListener('resize', handleResize)
    }, [])

    return windowSize;
}