import { action, makeObservable, observable } from "mobx";

interface IUser {
	email: string;
	token: string;
}

class AuthenticationStore {
	public isAuth = false;
	public user: IUser = { email: "", token: "" };

	constructor() {
		makeObservable(this, {
			isAuth: observable,
			user: observable,
			login: action,
			logout: action,
			isLoggedIn: action,
		});
	}

	login({ email, token }: IUser) {
		this.isAuth = this.isLoggedIn();
		this.user = { email, token };
	}

	isLoggedIn(): boolean {
		const token = localStorage.getItem("token");
		if (!token) return false;
		return true;
	}

	logout() {
		this.isAuth = false;
		this.user = { email: "", token: "" };
	}
}

const authStore = new AuthenticationStore();
export default authStore;
