// emailValidator.js
const emailValidator = {
    validate(email, showToast, setLoading) {
        if (email === "") {
            showToast('error', 'Error', "Por favor, digite o seu e-mail.");
            setLoading(false);
            return false;
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            showToast('error', 'Error', `O e-mail ${email} informado é inválido, Por favor, digite um e-mail válido.`);
            setLoading(false);
            return false;
        }
        return true;
    }
};

export default emailValidator;
