// AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

interface IsAuthContextProps {
    children: React.ReactNode;
}

// Criação do contexto de autenticação. Este contexto irá armazenar e prover informações sobre o estado de login do usuário.
export const AuthContext = createContext<unknown>(null);

export const AuthProvider: React.FC<IsAuthContextProps> = ({ children }) => {
    // Define o estado para controlar se o usuário está logado.
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    // Efeito para verificar a existência de um token de autenticação no armazenamento da sessão.
    // Se um token for encontrado, atualiza o estado para indicar que o usuário está logado.
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setIsLoggedIn(true);
        }
    }, [])

    // O Provider do contexto AuthContext que disponibiliza o estado de login e a função para alterá-lo para os componentes filhos.
    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};

// Hook personalizado para facilitar o acesso ao AuthContext.
// Este hook permite que componentes consumam o contexto de autenticação de forma mais direta.
export const useAuth = () => useContext(AuthContext);
