import React, { useEffect, useState } from "react";

interface Props {
    message?: string | null
}

export const LoadSpinner: React.FC<Props> = ({ message }) => {

    const [msg, setMessage] = useState<string | null>();
    useEffect(() => {
        setMessage(message ?? " Processano informações.");
    }, [message])

    return (
        <>
            <div className="fixed inset-0 z-50 bg-gray-800/75 flex flex-col items-center justify-center text-zinc-50 space-y-3">
                {/* <Spinner size="lg" label={`Aguarde, ${msg}`}/> */}
                <div className="spinner"></div> 
                <span className="font-semibold">Aguarde, {msg}</span>
            </div>
        </>
    );
}
