import { Outlet } from "react-router-dom";
import ThemeContextProvider from "../../Providers/useTheme";
import { useLoadingSpinner } from "../../Providers/loader-context";
import { LoadSpinner } from "../../components/load-spinner";
import { IsNetworkConnection } from "../../helpers/is-network-connection";
import NavBar from "../../components/nav-bar";
import { BreadcrumbProvider } from "../../contexts/BreadcrumbContext";
import { MyBreadCrumb } from "../../components/breadcrumb";

export default function PaginaBase() {
    IsNetworkConnection();
    const { isSpinnerLoading } = useLoadingSpinner();


    return (
        <>
            <ThemeContextProvider>
                <div className={`antialiased text-zinc-50`}>
                    <div className="overflow-hidden min-h-screen">
                        <NavBar />
                        {isSpinnerLoading && <LoadSpinner />}
                        <main className="p-8 md:ml-64 h-auto pt-20 space-y-5 ">
                            <BreadcrumbProvider>
                                <MyBreadCrumb />
                                <Outlet />
                            </BreadcrumbProvider>
                        </main>
                    </div>
                </div>
            </ThemeContextProvider>
        </>
    );
}
