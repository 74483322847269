import { X } from "lucide-react";
import { type ReactNode } from "react";
import { Divider } from "../divider";

type ModalProps = {
    title: string
    description?: string | null
    children: ReactNode
    closeModal?: () => void
}

export const ModalCustom = ({ title, description, children, closeModal }: ModalProps) => {
    return (
        <>
            <div className="fixed inset-0 z-40 mt-0 bg-gray-900/75 px-4 flex items-center justify-center">
                <div className="w-[640px] rounded-xl py-5 px-6 shadow-shape bg-gs-light space-y-5">
                    <div className="space-y-2">
                        <div className="flex items-center justify-between">
                            <h1 className="text-lg font-semibold">{title}</h1>
                            <button onClick={closeModal}>
                                <X className="size-5" />
                            </button>
                        </div>
                        {description && (
                            <p className="text-sm text-zinc-400">
                                {description}
                            </p>
                        )}
                    </div>
                    <Divider />
                    {children}
                </div>
            </div>
        </>
    );
};
