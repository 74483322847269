/**
 * Função getStartOfMonth
 * 
 * Esta função retorna o primeiro dia do mês atual no formato 'YYYY-MM-DD'.
 * Ela não recebe nenhum parâmetro e utiliza a data atual como referência para calcular o início do mês.
 * 
 * Retorno:
 * - Uma string no formato 'YYYY-MM-DD', representando o primeiro dia do mês atual.
 */
const getStartOfMonth = (): string => {
    // Cria um objeto Date para a data de hoje
    const today = new Date();

    // Define o dia como o primeiro dia do mês atual
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    // Obtém o mês da data. Se o mês for menor que 10, adiciona um zero à esquerda
    const month = startOfMonth.getMonth() + 1 < 10 ? `0${startOfMonth.getMonth() + 1}` : startOfMonth.getMonth() + 1;

    // Obtém o dia da data. O primeiro dia do mês será sempre '01'
    const day = '01';

    // Retorna a data formatada no formato 'YYYY-MM-DD'
    return `${startOfMonth.getFullYear()}-${month}-${day}`;
}

/**
 * Função getAdjustedDate
 * 
 * Esta função retorna uma data ajustada subtraindo um número específico de dias da data atual.
 * No ambiente de desenvolvimento, ela retorna uma data fixa.
 * 
 * Parâmetros:
 * - daysToSubtract (opcional): Número de dias a subtrair da data atual. Valor padrão é 0.
 * 
 * Retorno:
 * - Uma string no formato 'YYYY-MM-DD', representando a data ajustada.
 */
const getAdjustedDate = (daysToSubtract = 0) => {
    // Cria um objeto Date com a data atual
    let date = new Date();

    // Subtrai o número de dias especificado pelo parâmetro `daysToSubtract` da data atual
    date.setDate(date.getDate() - daysToSubtract);

    // Obtém o ano da data ajustada
    const year = date.getFullYear();

    // Obtém o mês da data ajustada, adicionando um zero à esquerda se for necessário (para manter o formato 'MM')
    const month = String(date.getMonth() + 1).padStart(2, "0");

    // Obtém o dia da data ajustada, adicionando um zero à esquerda se for necessário (para manter o formato 'DD')
    const day = String(date.getDate()).padStart(2, "0");

    // Retorna a data formatada como 'YYYY-MM-DD'
    return `${year}-${month}-${day}`;
}

/**
 * Função getYesterday
 * 
 * Esta função retorna a data de ontem no formato 'YYYY-MM-DD'.
 * Ela não recebe nenhum parâmetro e utiliza a data atual como referência para calcular o dia anterior.
 * 
 * Retorno:
 * - Uma string no formato 'YYYY-MM-DD', representando a data de ontem.
 */
const getYesterday = () => {
    // Cria um objeto Date para a data de hoje
    const today = new Date();

    // Cria um novo objeto Date para a data de ontem, copiando a data de hoje
    const yesterday = new Date(today);

    // Subtrai um dia da data de ontem
    yesterday.setDate(today.getDate() - 1);

    // Obtém o mês da data de ontem. Se o mês for menor que 10, adiciona um zero à esquerda
    const month = yesterday.getMonth() + 1 < 10 ? `0${yesterday.getMonth() + 1}` : yesterday.getMonth() + 1;

    // Obtém o dia da data de ontem. Se o dia for menor que 10, adiciona um zero à esquerda
    const day = yesterday.getDate() < 10 ? `0${yesterday.getDate()}` : yesterday.getDate();

    // Retorna a data formatada no formato 'YYYY-MM-DD'
    return `${yesterday.getFullYear()}-${month}-${day}`;
}

/**
 * Função getToday
 * 
 * Esta função retorna a data de hoje no formato 'YYYY-MM-DD'.
 * Ela não recebe nenhum parâmetro e utiliza a data atual como referência para calcular o dia de hoje.
 * 
 * Retorno:
 * - Uma string no formato 'YYYY-MM-DD', representando a data de hoje.
 */
const getToday = (): string => {
    // Cria um objeto Date para a data de hoje
    const today = new Date();

    // Obtém o ano, mês e dia da data de hoje, formatando-os para dois dígitos onde necessário
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");

    // Retorna a data formatada como 'YYYY-MM-DD'
    return `${year}-${month}-${day}`;
}

export {
    getStartOfMonth,
    getAdjustedDate,
    getYesterday,
    getToday
}
