import { useState, useEffect } from 'react';
import type { IAuthTokenPayload } from '../Interfaces/IAuthTokenPayload';
import { decodeTokenJtw } from '../helpers/helpers';

const TOKEN_KEY = 'token'; // Chave usada no localStorage

export const useAuthToken = () => {
    const [token, setToken] = useState<string | null>(null);
    const [payload, setPayload] = useState<IAuthTokenPayload | null>(null);

    useEffect(() => {
        const storedToken = localStorage.getItem(TOKEN_KEY);
        if (storedToken) {
            setToken(storedToken);
            const decodedPayload = decodeTokenJtw(storedToken);
            setPayload(decodedPayload);
        }
    }, []);

    const saveToken = (newToken: string) => {
        localStorage.setItem(TOKEN_KEY, newToken);
        setToken(newToken);
        const decodedPayload = decodeTokenJtw(newToken);
        setPayload(decodedPayload);
    };

    const clearToken = () => {
        localStorage.removeItem(TOKEN_KEY);
        setToken(null);
        setPayload(null);
    };

    return { token, payload, saveToken, clearToken };
};
