import { CalendarDays, CircleDollarSign } from "lucide-react"
import { SublimacaoStatusComponent } from "../../components/status-sublimacao"
import type { IOrcamento } from "../../Interfaces/IOrcamento"
import { format, parseISO } from "date-fns"
import { ptBR } from "date-fns/locale"
import { formatCurrency } from "../../helpers/helpers"

type OrderServicoViewCardProps = {
    orderServicos: IOrcamento[] | undefined
}

export const OrderServicoListView = ({ orderServicos }: OrderServicoViewCardProps) => {
    const formatDate = (timestamp: string, withTime: boolean = false) => {
        const date = parseISO(timestamp);
        return format(date, withTime ? "dd MMM yyyy HH:mm:ss" : "dd MMM yyyy", { locale: ptBR });
    };

    return (
        <div className="grid grid-cols-12 gap-2">

            {orderServicos && orderServicos?.map((orderServico: IOrcamento, index: number) => (
                <div key={index} className="relative flex flex-col col-span-full bg-gs-light sm:col-span-6 xl:col-span-4 shadow-lg rounded-lg ext-zinc-300">

                    <div className="flex justify-between items-center mb-2 p-4">
                        <span className="ext-zinc-300 font-semibold">#{orderServico.id}</span>
                    </div>

                    <div className="flex justify-between items-center bg-gray-700 mb-2 p-4">
                        <div className="flex flex-col">
                            <div className="flex justify-start items-center gap-1">
                                <CircleDollarSign size={16} />
                                <span className="text-zinc-400"> Valor </span>
                            </div>
                            <span className="text-zinc-300 font-semibold">{formatCurrency(orderServico.valorTotal)}</span>
                        </div>
                        <div className="flex flex-col">
                            <div className="flex items-center gap-1">
                                <CalendarDays size={16} />
                                <span className="text-zinc-400"> Data </span>
                            </div>
                            <span className="text-zinc-300 font-semibold">{formatDate(orderServico.data)}</span>
                        </div>
                    </div>

                    <div className="inline-flex justify-end items-end p-4 flex-1">
                        <SublimacaoStatusComponent status={orderServico.statusSublimacao} />
                    </div>
                </div>
            ))}
        </div>
    )
}