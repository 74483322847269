import { useEffect, useState } from "react";
import { formattedStartOfMonth, formattedCurrentDate } from "../js/utilits";
import { DatePicker, Select, SelectItem } from "@nextui-org/react";
import { IFiltro } from "../Interfaces/IFiltro";
import { parseDate } from "@internationalized/date";
import { Button } from "./button";
import { SublimacaoStatus } from "../Interfaces/enums/SublimacaoStatus";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { StatusSublimacaoService } from "../services/StatusSublimacaoService";
import { useLoadingSpinner } from "../Providers/loader-context";
import type { ISublimacaStatus } from "../Interfaces/ISublimacaoStatus";

interface Props {
    closeModal: any;
    onDataShare: any;
    selectedTabStatus: string;
}

export const FiltroComponente: React.FC<Props> = ({
    onDataShare,
    selectedTabStatus
}) => {
    const { setIsLoading } = useLoadingSpinner();
    const [DtInicio, setDataInicial] = useState(parseDate(formattedStartOfMonth()));
    const [DtFinal, setDataFinal] = useState(parseDate(formattedCurrentDate()));
    const [statusFiltro, setStatusFiltro] = useState<string>(selectedTabStatus);

    const [filters, setFilters] = useState<ISublimacaStatus[] | []>([]);

    const statusFiltroSublimacao = new StatusSublimacaoService(setIsLoading)

    const { data: sublimacaoStatus } = useQuery({
        queryKey: ["stsSublimacao"],
        queryFn: () => statusFiltroSublimacao.get(),
        staleTime: 60000 * 3,
        refetchInterval: 60000 * 2.5,
        refetchOnWindowFocus: false,
        placeholderData: keepPreviousData,
    });

    useEffect(() => {
        setFilters(sublimacaoStatus ?? []);
    }, [sublimacaoStatus]);

    async function formData(e: any) {
        e.preventDefault();

        const filterSave: IFiltro = {
            DtInicio: DtInicio.toString(),
            DtFinal: DtFinal.toString(),
            Status: statusFiltro
        }
        console.log(filterSave);

        const filter = onDataShare(filterSave);
        sessionStorage.setItem("searchData", JSON.stringify(filterSave));

        return filter;
    }

    const stylesDateInput = {
        inputWrapper: [
            "dark:bg-gs",
            "dark:text-zinc-50",
            "text-black",
        ],
    };
    return (
        <form onSubmit={formData} className="form grid gap-6  w-full">
            {/* Campos de Data */}
            <div className="flex flex-row gap-2">
                <div className="w-full flex flex-col gap-y-2">
                    <DatePicker className="max-w-[284px]" dateInputClassNames={stylesDateInput} label="Data inicial" value={DtInicio} onChange={setDataInicial} />
                </div>
                <DatePicker className="max-w-[284px]" dateInputClassNames={stylesDateInput} defaultValue={DtFinal} label="Data final" onChange={setDataFinal} />
            </div>

            <div className="flex flex-row gap-3">
                <Select
                    label="Status"
                    placeholder="Selecione um Status"
                    defaultSelectedKeys={[statusFiltro]}
                    selectedKeys={[statusFiltro]}
                    classNames={{ trigger: ['dark:bg-gs'], popoverContent: ['dark:bg-gs'] }}
                    onChange={(e) => setStatusFiltro(e.target.value)}
                >
                    {filters && filters.map((cc: ISublimacaStatus) => (
                        <SelectItem key={cc.name} className="text-zinc-50">
                            {cc.label}
                        </SelectItem>
                    ))}
                </Select>
            </div>

            <div className="flex justify-end space-x-4">
                <Button>
                    Aplicar Filtros
                </Button>
            </div>
        </form>
    );
};

interface Selection {
    key: SublimacaoStatus;
    name: string;
    label: string;
}

export const tipoStatus: Selection[] = [
    {
        key: SublimacaoStatus.AbertoEsperandoAprovacao,
        name: "AbertoEsperandoAprovacao",
        label: "Aberto (Esperando Aprovação)"
    },
    {
        key: SublimacaoStatus.Aprovado,
        name: "Aprovado",
        label: "Aprovado"
    },
    {
        key: SublimacaoStatus.FilaDeImpressao,
        name: "FilaDeImpressao",
        label: "Fila de Impressão"
    },
    {
        key: SublimacaoStatus.Impresso,
        name: "Impresso",
        label: "Impresso"
    },
    {
        key: SublimacaoStatus.Entregue,
        name: "Entregue",
        label: "Entregue"
    },
    {
        key: SublimacaoStatus.FinanceiroSemFinalizar,
        name: "FinanceiroSemFinalizar",
        label: "Financeiro Sem Finalizar"
    },
    {
        key: SublimacaoStatus.CanceladoServico,
        name: "CanceladoServico",
        label: "Cancelado Serviço"
    },
    {
        key: SublimacaoStatus.FaturadoPeloPDV,
        name: "FaturadoPeloPDV",
        label: "Faturado pelo PDV"
    },
    {
        key: SublimacaoStatus.ServicoEmAndamento,
        name: "ServicoEmAndamento",
        label: "Serviço em Andamento"
    },
    {
        key: SublimacaoStatus.ServicoExecutadoAguardandoClienteFinanceiro,
        name: "ServicoExecutadoAguardandoClienteFinanceiro",
        label: "Serviço Executado Aguardando Cliente"
    },
    {
        key: SublimacaoStatus.Todos,
        name: "Todos",
        label: "Todos"
    },
];
