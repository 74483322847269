import { HeaderComponent } from "../../components/hearder-components";
import { Filter } from "lucide-react";
import { OrderServicoListView } from "./order-servico-list-view";
import { Button } from "../../components/button";
import { ModalCustom } from "../../components/modal";
import { FiltroComponente } from "../../components/filtro-modal";
import { NotFoundData } from "../../components/not-found";
import { Divider } from "../../components/divider";
import { useOrcamentos } from "./useOrcamentos";
import { formatCurrency } from "../../helpers/helpers";

/**
 * Componente funcional que representa a página de Ordem de Serviços.
 * 
 * Este componente utiliza o hook `useOrcamentos` para obter dados relacionados a orçamentos,
 * incluindo informações de carregamento, erro e dados específicos do orçamento.
 * 
 * @returns {JSX.Element} O componente JSX que renderiza a página de Ordem de Serviços.
 * 
 * @component
 * 
 * @example
 * ```tsx
 * import { OrderServico } from './path/to/component';
 * 
 * const App = () => (
 *   <OrderServico />
 * );
 * ```
 * 
 * @hook
 * @name useOrcamentos
 * 
 * @remarks
 * O componente exibe um cabeçalho com título e subtítulo, um botão para abrir um modal de filtro,
 * um indicador de carregamento, mensagens de erro e uma lista de ordens de serviço.
 * 
 * @returns {JSX.Element} O JSX que representa a interface da página de Ordem de Serviços.
 */
export const OrderServico: React.FC<any> = () => {

    const {
        data: orcamentos,
        isLoading,
        isFetching,
        isError,
        error,
        isModalFilter,
        selectedDescriptionStatus,
        payload,
        hendlerOpenModalFilter,
        selectedStatus,
        handleModalData } = useOrcamentos();

    const totalOrdemServico = orcamentos?.reduce((acc, orcamento) => acc + orcamento.valorTotal, 0) || 0;

    return (
        <div className="flex flex-col gap-4">
            <HeaderComponent
                title={`${payload?.razaoSocial ? payload?.razaoSocial + ' - ' + payload?.CNPJ : "Ordem Serviços"}`}
                subTitle={`Lista de ordem de serviços`}
                buttons={
                    <div className="flex flex-row gap-2">
                        <div>
                            <Button onClick={hendlerOpenModalFilter} size="default" variant="transpatent">
                                <Filter size={18} />
                                <span className="hidden xl:inline">Filter</span>
                            </Button>
                        </div>
                    </div>
                }
            />
            <Divider />
            {/* Spinner de Loading */}
            {(isLoading || isFetching) ? (
                <div className="flex justify-center items-center">
                    <div className="loader">Carregando...</div>
                </div>
            ) : (
                isError && (error?.message === "O recurso solicitado não foi encontrado.") ? (
                    <NotFoundData />
                ) : (
                    <>
                        <div className="flex flex-col sm:flex-row justify-center sm:justify-between items-center sm:items-start gap-2">
                            <div className="text-center sm:text-left flex flex-col">
                                <span className="font-bold">{selectedDescriptionStatus},</span>
                                {orcamentos && orcamentos?.length > 1 ? (
                                    <div className="">
                                        <span className="font-bold">{orcamentos?.length.toString().padStart(2, "0")}</span>
                                        <span className="text-sm"> Ordens Serviço encontradas.</span>
                                    </div>
                                ) : (`01 Ordem Serviço encontrada.`)}
                            </div>
                            <div className="flex flex-col items-center sm:items-end">
                                <span className="text-sm">Total:</span>
                                <span className="text-lg font-bold">{formatCurrency(totalOrdemServico)}</span>
                            </div>
                        </div>


                        <OrderServicoListView orderServicos={orcamentos} />
                    </>
                )
            )}
            {isModalFilter && (
                <ModalCustom
                    title="Filtrar"
                    closeModal={hendlerOpenModalFilter}>
                    <FiltroComponente
                        onDataShare={handleModalData}
                        closeModal={hendlerOpenModalFilter}
                        selectedTabStatus={selectedStatus}
                    />
                </ModalCustom>
            )}

        </div>
    )
}