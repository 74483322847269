import { Link } from "react-router-dom";
import notFound from "../assets/404.svg"
import { appUrl } from "../config";
interface Props {
    loadCard?: boolean;
}

export const NotFoundHandler: React.FC<Props> = () => {

    return (
        <>
            <div className="flex flex-col justify-center items-center min-h-screen text-center bg-gs text-zinc-50 p-3">
                <div className="flex flex-col justify-center items-center">
                    <img src={appUrl + notFound} alt="404" width="250" />
                    <h3 className="text-xl font-semibold mt-3">Ops! Essa página não pode ser encontrada</h3>
                    <span className="text-sm mt-1">A página que você está tentando acessar provavelmente foi alterada ou removida.</span>
                </div>
                <div className="mt-5">
                    <Link to={'./home'} className="w-80 flex justify-center bg-[#0157A5] rounded-lg hover:bg-slate-400 p-3 uppercase text-sm text-zinc-50 font-semibold">
                        Voltar
                    </Link>
                </div>
            </div>
        </>
    );
}
