import { Cable, Home, UserRound } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { tokenPayload, type IJwtPayload } from "../../helpers/jwt";
import { MenuItem, type PropsMenuItem } from "./menu-item";
import { LogoutApp } from "../logout";

interface PropsSideMenu {
    id: string;
    isOpen: boolean;
    closeMenu: () => void;
}

export const AsideMenu: React.FC<PropsSideMenu> = ({ id, isOpen, closeMenu }) => {

    const [userData, setUserData] = useState<IJwtPayload>({});

    const isAllowedDomain = (email: string | undefined): boolean => {
        if (!email) return false;
        return !email.endsWith('@gsretail.com.br');
    };

    const menuItems: PropsMenuItem[] = [
        { icon: <Home />, title: "Home", to: "/home", allowed: true },
        { icon: <Cable />, title: "Ordem de Serviço", to: "/order-servico", allowed: isAllowedDomain(userData && userData.email) }
    ];

    const asideRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        setUserData(tokenPayload() ?? null);

        const handleClickOutside = (event: MouseEvent) => {
            if (asideRef.current && !asideRef.current.contains(event.target as Node)) {
                closeMenu();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [closeMenu]);

    return (
        <aside ref={asideRef} id={id} className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform ${isOpen ? '' : '-translate-x-full'} bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-900 dark:border-gray-800`} aria-label="Sidebar">
            <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-900">
                <ul className="space-y-2">
                    {menuItems.map((item: PropsMenuItem, index: number) => (
                        <MenuItem key={index} id={index} icon={item.icon} title={item.title} to={item.to} allowed={item.allowed} />
                    ))}
                </ul>

                <div className=" sticky top-[100vh] dark:text-gray-400">
                    <div className="flex flex-row h-20 items-center gap-3 border-t-1">
                        <div className="flex gap-2 items-center w-4/5">
                            <UserRound className="text-gray-800 bg-slate-50 border-2 rounded-full" size={32} />
                            <div className="flex flex-col text-xs text-start">
                                <span>{userData.nome}</span>
                                <span>{userData.email}</span>
                            </div>
                        </div>
                        <div className="w-1/5">
                            <LogoutApp />
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    );
}


