import { AuthProvider } from "./Providers/AuthProvider";
import AppRoutes from "./AppRoutes";
import { LoaderProvider } from "./Providers/loader-context";
import { Toaster } from "sonner";
import { ShareDataProvider } from "./Providers/ShareDataContext";
import { ErrorBoundary } from "./error-boundary";

export default function App() {


    return (
        <AuthProvider>
            <ShareDataProvider>
                <ErrorBoundary>
                    <LoaderProvider>
                        <AppRoutes />
                        <Toaster richColors />
                    </LoaderProvider>
                </ErrorBoundary>
            </ShareDataProvider>
        </AuthProvider>
    );
}
