import { useEffect, useState } from "react";
import { IJwtPayload, tokenPayload } from "../../helpers/jwt";
import { useBreadcrumb } from "../../contexts/BreadcrumbContext";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { InputWithLabel } from "../../components/input";
import { AccountService } from "../../services/AccountService";
import { useLoadingSpinner } from "../../Providers/loader-context";
import { toast } from "sonner";

const schemaResetSenhaSenha = yup.object({
    senhaAtual: yup.string().required("Por favor, informe sua senha atual."),
    senhaNova: yup.string().required("Por favor, informe sua nova senha").min(6, "Sua senha deve conter no mínimo 6 caracteres"),
    confirmaSenha: yup.string().oneOf([yup.ref('senhaNova'), ""], 'As senhas devem coincidir').required("Por favor, confirme sua nova senha"),
}).required();


type FormDataResetNovaSenha = yup.InferType<typeof schemaResetSenhaSenha>;

const UseAccount: React.FC<any> = () => {
    const { setBreadcrumb } = useBreadcrumb();
    const [userData, setUserData] = useState<IJwtPayload>({});
    const { setIsLoading } = useLoadingSpinner();

    const { register, handleSubmit, formState: { errors }, reset } = useForm<FormDataResetNovaSenha>({
        resolver: yupResolver(schemaResetSenhaSenha),
        defaultValues: {
            senhaAtual: "",
            senhaNova: "",
            confirmaSenha: ""
        }
    })

    useEffect(() => {
        setBreadcrumb([{ label: "Dados do Usúario" }])
        try {
            setUserData(tokenPayload() ?? null);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const onSubmit = async (formData: FormDataResetNovaSenha) => {
        try {
            const accountService = new AccountService(setIsLoading);
            //await mutation.mutateAsync(formData);
            await accountService.resetSenha(formData)
            toast.success("Senha alterada com sucesso", { position: "top-center" });
            reset();
        } catch (error: any) {
            console.log(error?.response.data.erro.message);
            toast.error(error.response?.data.erro.message, { position: "top-center" });
        }
    };

    return (<>
        <div className="flex min-h-full flex-col px-6 py-12 lg:px-8 space-y-4">
            <form className="flex flex-col gap-3">
                <div className="w-full">
                    <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">
                        Minha Conta
                    </h2>
                    <span className="text-gray-900 dark:text-gray-400 font-bold">Gerencie as informações de conta, dados pessoais</span>
                </div>

                <div className="w-full">
                    <div className="w-full">
                        <InputWithLabel
                            label="Nome Completo" value={userData.nome} />
                    </div>
                    <div className="w-full">
                        <InputWithLabel
                            label="Nome Completo" value={userData.email} />
                    </div>
                </div>
            </form>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3 mb-6">

                <div className="w-full">
                    <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">
                        Alterar Senha
                    </h2>
                    <span className="text-gray-900 dark:text-gray-400 font-bold"></span>
                </div>

                <div className="w-full">
                    <InputWithLabel
                        label="Senha Atual"
                        placeholder="***********"
                        type="password"
                        {...register("senhaAtual")} variant={errors?.senhaAtual ? "danger" : "primary"} />
                    <span className="text-red-600 text-sm m-0">{errors?.senhaAtual?.message}</span>
                </div>

                <div className="w-full">
                    <InputWithLabel
                        label=" Nova Senha"
                        placeholder="***********"
                        type="password"
                        {...register("senhaNova")} variant={errors?.senhaNova ? "danger" : "primary"} />
                    <span className="text-red-600 text-sm m-0">{errors?.senhaNova?.message}</span>
                </div>

                <div className="w-full">
                    <InputWithLabel
                        label="Confirma Nova Senha"
                        placeholder="***********"
                        type="password"
                        {...register("confirmaSenha")} variant={errors?.confirmaSenha ? "danger" : "primary"} />
                    <span className="text-red-600 text-sm m-0">{errors?.confirmaSenha?.message}</span>
                </div>

                <div className="w-full">
                    <button type="submit" className="flex w-full justify-center rounded-md bg-indigo-700 px-3 py-4 text-sm font-semibold leading-6 dark:text-gray-100 text-gray-900   shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Solicitar alterações
                    </button>
                </div>
            </form>
        </div>

    </>)
}

export default UseAccount;